import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import * as yup from "yup"
import { useMutation } from '@apollo/client';

import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import SelectSubCategory from '../../select/SubCategorySelect';
import CategoryFilter from '../../filters/CategoryFilter';
import Content from '../../../graphql/mutations/Content';
import SubjectFilter from '../../filters/SubjectFilter';
import { handleTranslate } from '../../../service/translate';
import Exercise from '../../../graphql/mutations/Exercise';
import QuestionTypeSelect from '../../select/QuestionTypeSelect';

interface ExerciseCreateType {
    question: string
    questionHi: string
    label: string
    labelHi: string
    subCategoryId: number;
    questionTypeId: number;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        question: yup.string().required("Question is required!"),
        questionHi: yup.string().required("Question (In hindi) is required!"),
        label: yup.string().required("Answer is required!"),
        labelHi: yup.string().required("Answer (In hindi) is required!"),
        subCategoryId: yup.number().required("Sub Category is required!"),
        questionTypeId: yup.number().required("Question Type is required!"),
    })
    .required()

const CreateExercise: React.FC<Props> = () => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [categoryId, setCategoryId] = useState<number>()
    const [subjectId, setSubjectId] = useState<number>()
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createExercise] = useMutation(Exercise)

    const onFormSubmit = (v: ExerciseCreateType) => {
        createExercise({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                console.log('data', data);
                reset()
                notification({message: "Exercise created successfully!"})
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetExercises']
        })
    }

    const handleCategoryChange = useCallback((v: number) => {
        setCategoryId(v)
    },[setValue])
    
    const handleSubjectChange = useCallback((v: number) => {
        setSubjectId(v)
    },[])

    const handleFocus = useCallback(async (idx: any) => {
        const name:any = idx.slice(0, -2)
        const hindi = await handleTranslate(getValues(name))
        setValue(idx, hindi)
    }, [getValues, setValue])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Exercise
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SubjectFilter setOptionValue={handleSubjectChange} value={subjectId} />
                <CategoryFilter setOptionValue={handleCategoryChange} value={categoryId} subjectId={subjectId} />
                <SelectSubCategory
                    errors={errors}
                    label='Select Sub Category'
                    name='subCategoryId'
                    register={register}
                    categoryId={categoryId}
                    disabled={!categoryId}
                />
                <TextField
                    label="Question"
                    placeholder="Enter question"
                    customId="question"
                    type="text"
                    register={register}
                    errors={errors?.question?.message}
                />
                <TextField
                    label="Question (In Hindi)"
                    placeholder="Enter Question (In hindi)"
                    customId="questionHi"
                    onFocus={handleFocus}
                    type="text"
                    register={register}
                    errors={errors?.questionHi?.message}
                />
                <Textarea
                    label="Answer"
                    placeholder="Enter Answer"
                    customId="label"
                    register={register}
                    errors={errors?.label?.message}
                />
                <Textarea
                    label="Answer (In Hindi)"
                    placeholder="Enter Answer (In hindi)"
                    customId="labelHi"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.labelHi?.message}
                />
                <QuestionTypeSelect
                    errors={errors}
                    label='Select Question Type'
                    name='questionTypeId'
                    register={register}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateExercise