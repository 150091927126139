import { gql } from "@apollo/client";

export default gql`
query GetExercises($where: JSON){
  exercises(where: $where) {
    id
    question
    questionHi
    solutions {
      id
      label
      labelHi
      steps
    }
  }
}
`