import { useState } from 'react';
import axios from 'axios';
import CheckBoxComponent from './common/Checkbox';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { notification } from '../service';
import { useMutation } from '@apollo/client';
import BulkQuestionCreate from '../graphql/mutations/BulkQuestionCreate';

const PdfUploader = () => {
  const [parsedText, setParsedText] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [formData, setFormData] = useState<string|any>()
  const [isPdf, setIsPdf] = useState<boolean>(false)

  const navigate = useNavigate()
  const { id } = useParams()
  const [bulkQuestionCreate] = useMutation(BulkQuestionCreate)
  console.log('id', id);
  // Handle file drop
  const handleDrop = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (file.type === 'application/pdf') {
        await handleFileUpload(file);
      } else {
        alert('Please upload a valid PDF file.');
      }
    }
  };

  // Handle file input change
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file.type === 'application/pdf') {
      await handleFileUpload(file);
    } else {
      alert('Please upload a valid PDF file.');
    }
  };

  // Handle drag events
  const handleDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // File upload and parsing
  const handleFileUpload = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://localhost:3001/parse', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setParsedText(response.data);
    } catch (error) {
      console.error('Error parsing PDF:', error);
    }
  };

  const handleSubmit = () => {
    if(!formData || !id) return notification({message: "No data provided", type: 'warning'})
    console.log("formData", formData);
    console.log("typeof formData", typeof formData);
    const jsonParsed = JSON.parse(formData)
    console.log('jsonParsed', jsonParsed, typeof jsonParsed);
    
    // return
    bulkQuestionCreate({
      variables: {
        input: {
          quizId: Number(id),
          questions: JSON.parse(formData)
        }
      },
      onCompleted: (data) => {
        if(data){
          notification({message: "Bulk Inserted successfully!"})
          setFormData(undefined)
          navigate(-1)
        }
      },
      onError: (err) => {
        notification({message: err.message, type: "danger"})
      },
      refetchQueries: ['GetQuizzes']
    })
  }

  return (
    <div className='px-3'>
      <div className='d-flex align-items-center py-2'>
        <CheckBoxComponent checked={isPdf} label='Is PDF' onChange={(e) => setIsPdf(e.target.checked)} />
        <Button onClick={handleSubmit} disabled={!formData} className='ms-5' variant='outlined'>Submit</Button>
      </div>
      {isPdf ? <>
        <div
          className={`drop-zone ${dragActive ? 'active' : ''}`}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
        >
          <p>Drag & Drop your PDF here or click to browse</p>
          <input
            type="file"
            accept="application/pdf"
            className="file-input"
            onChange={handleFileChange}
          />
        </div>
        {parsedText && (
          <div style={{ overflowY: 'auto', border: '1px solid red', height: 500 }}>
            <pre style={{ color: 'gray' }}>{parsedText}</pre>
          </div>
        )}
      </> :
        <textarea onChange={(e) => setFormData(e.target.value)} rows={22} placeholder="JSON Data" className="text-field px-2 rounded"></textarea>
      }
    </div>
  );
};

export default PdfUploader;
