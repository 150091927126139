import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import * as yup from "yup"
import { useMutation, useQuery } from '@apollo/client';

import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import SelectSubCategory from '../../select/SubCategorySelect';
import CategoryFilter from '../../filters/CategoryFilter';
import Content from '../../../graphql/mutations/Content';
import SubjectFilter from '../../filters/SubjectFilter';
import { handleTranslate } from '../../../service/translate';
import Exercise from '../../../graphql/mutations/Exercise';
import QuestionTypeSelect from '../../select/QuestionTypeSelect';
import GetExercise from '../../../graphql/queries/GetExercise';
import UpdateExercise from '../../../graphql/mutations/UpdateExercise';

interface ExerciseEditType {
    question: string
    questionHi: string
    label: string
    labelHi: string
    subCategoryId: number;
    questionTypeId: number;
}

interface Props{
    handleClose: () => void;
    id?: number;
}

const schema = yup
    .object({
        question: yup.string().required("Question is required!"),
        questionHi: yup.string().required("Question (In hindi) is required!"),
        label: yup.string().required("Answer is required!"),
        labelHi: yup.string().required("Answer (In hindi) is required!"),
        subCategoryId: yup.number().required("Sub Category is required!"),
        questionTypeId: yup.number().required("Question Type is required!"),
    })
    .required()

const EditExercise: React.FC<Props> = ({ id, handleClose }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [categoryId, setCategoryId] = useState<number>()
    const [subjectId, setSubjectId] = useState<number>()
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const {loading, data} = useQuery(GetExercise, {
        fetchPolicy: "network-only",
        variables: {
            exerciseId: id
        }
    })
    
    const [updateExercise] = useMutation(UpdateExercise)

    useEffect(() => {
        if(!loading && data && data.exercise){
            const {question, questionHi, solutions} = data.exercise
            setValue("question", question)
            setValue("questionHi", questionHi)
            setValue("label", solutions[0].label)
            setValue("labelHi", solutions[0].labelHi)
        }
    }, [loading, setValue, data])

    const onFormSubmit = (v: ExerciseEditType) => {
        updateExercise({
            variables: {
                input: {...v, id, solutionId: data?.exercise?.solutions[0].id}
            },
            onCompleted: (data) => {
                console.log('data', data);
                notification({message: "Exercise created successfully!"})
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetExercises']
        })
    }

    const handleCategoryChange = useCallback((v: number) => {
        setCategoryId(v)
    },[setValue])
    
    const handleSubjectChange = useCallback((v: number) => {
        setSubjectId(v)
    },[])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Exercise
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SubjectFilter setOptionValue={handleSubjectChange} value={subjectId} />
                <CategoryFilter setOptionValue={handleCategoryChange} value={categoryId} subjectId={subjectId} />
                <SelectSubCategory
                    errors={errors}
                    label='Select Sub Category'
                    name='subCategoryId'
                    register={register}
                    categoryId={categoryId}
                    value={data?.exercise?.subCategoryId}
                    setValue={setValue}
                />
                <TextField
                    label="Question"
                    placeholder="Enter question"
                    customId="question"
                    type="text"
                    register={register}
                    errors={errors?.question?.message}
                />
                <TextField
                    label="Question (In Hindi)"
                    placeholder="Enter Question (In hindi)"
                    customId="questionHi"
                    type="text"
                    register={register}
                    errors={errors?.questionHi?.message}
                />
                <Textarea
                    label="Answer"
                    placeholder="Enter Answer"
                    customId="label"
                    register={register}
                    errors={errors?.label?.message}
                />
                <Textarea
                    label="Answer (In Hindi)"
                    placeholder="Enter Answer (In hindi)"
                    customId="labelHi"
                    register={register}
                    errors={errors?.labelHi?.message}
                />
                <QuestionTypeSelect
                    errors={errors}
                    label='Select Question Type'
                    name='questionTypeId'
                    register={register}
                    value={data?.exercise?.questionTypeId}
                    setValue={setValue}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditExercise