import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';

interface Props{
    onChange: (e:any) => void
    checked: boolean
    label: string
}
const CheckBoxComponent:React.FC<Props> = ({onChange, checked,  label}) => {
    const { theme } = useSelector((state:RootState) => state.sidebar)
  return (
    <div className={`${theme} check-box-container`}>
        <Checkbox checked={checked} id='check-box' onChange={onChange} />
        <label htmlFor="check-box" className='ms-2'>{label}</label>
    </div>
  )
}

export default CheckBoxComponent