import PdfUploader from '../components/PdfUploader';

const AddQuestions = () => {
  return (
    <div>
        <PdfUploader />
    </div>
  )
}

export default AddQuestions