import { gql } from "@apollo/client";

export default gql`
query GetExercise($exerciseId: Int!){
  exercise(id: $exerciseId) {
    id
    question
    questionHi
    questionTypeId
    subCategoryId
    solutions {
      id
      label
      labelHi
      steps
    }
  }
}
`