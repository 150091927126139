import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client';

import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import SubjectSelect from '../../select/SubjectSelect';
import GetCategory from '../../../graphql/queries/GetCategory';
import UpdateCategory from '../../../graphql/mutations/UpdateCategory';
import EducationSelect from '../../select/EducationSelect';

interface CategoryCreateType {
    name: string;
    nameHi: string;
    subjectId: string;
    fromEducationId: number;
    toEducationId: number;
}

interface Props {
    handleClose: () => void;
    id: number
}

const schema = yup
    .object({
        name: yup.string().required("Category name is required!"),
        nameHi: yup.string().required("Category name (in hindi) is required!"),
        subjectId: yup.string().required("Subject is required!"),
        fromEducationId: yup.number().required("From Class is required!"),
        toEducationId: yup.number().required("To class is required!"),
    })
    .required()

const EditCategory: React.FC<Props> = ({ handleClose, id }) => {
    const [subject, setSubject] = useState<number>()
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const { data, loading, error } = useQuery(GetCategory, {
        fetchPolicy: "network-only",
        variables: {
            categoryId: id
        }
    })

    useEffect(() => {
        if (!loading && data && data.category) {
            const { name, subjectId, nameHi, fromEducationId, toEducationId } = data.category;
            setSubject(subjectId)
            setValue('name', name)
            setValue('nameHi', nameHi)
        }
    }, [loading, data, error, setValue])

    const [updateCategory] = useMutation(UpdateCategory)

    const onFormSubmit = (v: CategoryCreateType) => {
        updateCategory({
            variables: {
                input: {
                    ...v,
                    subjectId: subject,
                    id
                }
            },
            onCompleted: (data) => {
                console.log('data', data);
                if(data){
                    notification({message: 'Category updated successfully!'})
                }
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetCategories']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Category
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <EducationSelect
                    errors={errors} 
                    label='Select From Class' 
                    name='fromEducationId' 
                    register={register}
                    setValue={setValue}
                    value={data?.category?.fromEducationId?.toString()}
                />
                <EducationSelect 
                    errors={errors} 
                    label='Select To Class' 
                    name='toEducationId' 
                    register={register} 
                    value={data?.category?.toEducationId?.toString()}
                    setValue={setValue}
                />
                <SubjectSelect
                    errors={errors}
                    label='Select Subject'
                    name='subjectId'
                    register={register}
                    setValue={setValue}
                    value={subject?.toString()}
                />
                <TextField
                    label="Category"
                    placeholder="Enter category name"
                    customId="name"
                    type="text"
                    register={register}
                    errors={errors?.name?.message}
                />
                <TextField
                    label="Category (In Hindi)"
                    placeholder="Enter category name (in hindi)"
                    customId="nameHi"
                    type="text"
                    register={register}
                    errors={errors?.nameHi?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditCategory