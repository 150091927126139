import React from 'react'
import SelectField from '../common/SelectField'

interface Props {
    errors: any;
    register: any;
    name: string;
    label: string;
    value?: string;
    setValue?: (x: any, y: any) => void;
}

const defalutValue: any = [
    {label: "Long", value: 1},
    {label: "Short", value: 2},
    {label: "Numerical", value: 3},
    {label: "Experimental", value: 4},
]

const QuestionTypeSelect: React.FC<Props> = ({errors, label, name, register, setValue, value}) => {
    return (
        <SelectField
            data={defalutValue}
            errors={errors}
            label={label}
            name={name}
            register={register}
            setValue={setValue}
            value={value}
        />
    )
}

export default QuestionTypeSelect