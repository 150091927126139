import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoutes";
import UserDetails from "./pages/UserDetails";
import Users from "./pages/Users";

import PageNotFound from "./pages/404";
import Notifications from "./pages/Notifications";
import Quizzes from "./pages/Quizzes";
import Campaigns from "./pages/Campaigns";
import Questions from "./pages/Questions";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import Contents from "./pages/Contents";
import FeedBack from "./pages/FeedBack";
import Subject from "./pages/Subject";
import Topics from "./pages/Topics";
import Exercise from "./pages/Exercise";
import Question from "./pages/Question";
import Quiz from "./pages/Quiz";
import AddQuestions from "./pages/AddQuestions";

interface RouterProps{
    auth: boolean
}
const Routers:React.FC<RouterProps> = ({ auth }) => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<PrivateRoute auth={auth} Component={<Users />}/>} />
                <Route path="/users" element={<PrivateRoute auth={auth} Component={<Users />}/>} />
                <Route path="/users/:id" element={<PrivateRoute auth={auth} Component={<UserDetails /> } />} />
                <Route path="/notification" element={<PrivateRoute auth={auth} Component={<Notifications /> }/>} />
                <Route path="/quizzes" element={<PrivateRoute auth={auth} Component={<Quizzes /> }/>} />
                <Route path="/quizzes/create-questions/:id" element={<PrivateRoute auth={auth} Component={<AddQuestions /> }/>} />
                <Route path="/quiz/:id" element={<PrivateRoute auth={auth} Component={<Quiz /> }/>} />
                <Route path="/campaigns" element={<PrivateRoute auth={auth} Component={<Campaigns /> }/>} />
                <Route path="/questions" element={<PrivateRoute auth={auth} Component={<Questions /> }/>} />
                <Route path="/question/:id" element={<PrivateRoute auth={auth} Component={<Question /> }/>} />
                <Route path="/category" element={<PrivateRoute auth={auth} Component={<Category /> }/>} />
                <Route path="/subject" element={<PrivateRoute auth={auth} Component={<Subject /> }/>} />
                <Route path="/sub-category" element={<PrivateRoute auth={auth} Component={<SubCategory /> }/>} />
                <Route path="/topic" element={<PrivateRoute auth={auth} Component={<Topics /> }/>} />
                <Route path="/contents" element={<PrivateRoute auth={auth} Component={<Contents /> }/>} />
                <Route path="/exercise" element={<PrivateRoute auth={auth} Component={<Exercise /> }/>} />
                <Route path="/feedback" element={<PrivateRoute auth={auth} Component={<FeedBack /> }/>} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default Routers;
